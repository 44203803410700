<template>
  <div class="page-with-title">
    <page-title>
      <h1>Aprovação biografia de <u>{{ biography.artista }}</u></h1>
    </page-title>
    <div class="p-4">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label>ID</label>
            <input
              v-model="biography.id"
              readonly
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label>ID artista</label>
            <input
              v-model="biography.id_artista"
              readonly
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Artista</label>
            <input
              v-model="biography.artista"
              readonly
              type="text"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col" v-if="originalBiography">
          <div class="form-group">
            <label>Biografia atual</label>
            <textarea
              :value="originalBiography"
              readonly
              rows="10"
              class="form-control"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Biografia nova</label>
            <textarea
              v-model="biography.biografia"
              rows="10"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="mt-4 mb-3 text-right">
        <button
          class="btn btn-2 btn-bigger mr-3"
        >
          <b><i class="fa fa-check" /> Aprovar nova biografia</b>
        </button>
        <button
          class="btn btn-link text-danger"
        >
          <b><i class="fa fa-close" /> Recusar nova biografia</b>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/titles/PageTitle'
  import { getContributionBiography } from '@/api/contributions'
  import { getArtist } from '@/api/artists'

  export default {
    components: { PageTitle },
    data: () => ({
      biography: {},
      artist: {}
    }),
    async mounted () {
      await this.fetchBiography()
      await this.fetchArtist()
    },
    computed: {
      originalBiography () {
        return this.artist.biography ? this.artist.biography.biografia : null
      }
    },
    methods: {
      async fetchBiography () {
        this.biography = await getContributionBiography(this.$route.params.id)
      },
      async fetchArtist () {
        this.artist = await getArtist(this.biography.id_artista)
      }
    }
  }
</script>